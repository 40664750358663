body {
  margin-bottom: 140px;
  background: #DDDDDD fixed;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.header {
  background: #222831;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 10px;
  margin-bottom: 100px;
}

/* skills */

.skills {
  background: #222831 fixed;
  color: #DDDDDD;
  margin-top: 100px;
}

.technologies-title {
  text-align: center;
  padding-top: 40px;
}

.technologies {
  padding-left: 400px;
  padding-right: 400px;
}

.technologies-list {
  list-style: none;
  text-align: center;
  padding: 30px;
}

.title {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 50px;
  color: #222831;
  font-weight: bold;
}

.info-para {
  color: #222831;
  width: 70%;
}

.link {
  color: #222831;
  font-weight: bold;
}

.link:hover {
  color: #F05454;
}

.thumbnail {
  width: 50px;
  margin: 20px;
}

.home-thumbnail {
  width: 35px;
  margin: 20px;
}

.logo {
  width: 150px;
  margin-top: 15px;
}

/* navigation css */

.nav-link {
  color: #DDDDDD;
  font-size: 15px;
  font-weight: bold;
  position: sticky;
}

.nav-link:hover {
  color: #DDDDDD;
  background-color: #F05454;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 0.4);
  border-radius: 5px;
  padding: 5px;
  transition: ease-in-out 0.3s;
}

.nav-item {
  margin: 14px;
}

.navbar-toggler {
  color: #DDDDDD;
}

.navigation-bar {
  text-align: left;
  padding-top: 30px;
}

/* footer css */

.footer {
  border-top: 1px, solid, #F05454;
  text-align: center;
  margin-top: 100px;
  padding: 20px;
  height: 50px;
  width: 100%;
}

/* homepage css */

.name {
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 20px;
  font-size: 38px;
  color: #222831;
}

.personal-info {
  text-align: center;
  color: #222831;
}

.photo {
  border: 1px solid #30475E;
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 0.4);
  padding: 2px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 30px;
  width: 225px;
}

.headline {
  margin-bottom: 25px;
  margin-left: 20px;
  font-size: 30px;
  color: #252323;
}

.interested-in {
  margin-bottom: 25px;
  margin-left: 20px;
  font-size: 22px;
  color: #252323;
}

/* certificates css */

.certificate-heading {
  color: #222831;
  font-size: 25px;
  margin-bottom: 25px;
  margin-top: 20px;
}

.certificate-para {
  color: #222831;
  width: 70%;
}

.divider {
  color: #F05454;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* projects css */

.project-language {
  text-align: left;
  margin-top: 60px;
  margin-bottom: 60px;
  color: #222831;
  font-weight: bold;
}

.project-title {
  margin-top: 40px;
  margin-bottom: 30px;
  color: #222831;
  font-weight: bold;
}

.custom-card {
  color: #222831;
}

/* contact-form */

.form-label {
  color: #222831;
}

.form-heading {
  color:#222831;
  font-size: 18px;
  margin-bottom: 40px;
}

.submit-button {
  background-color: #30475E;
  color: #DDDDDD;
  padding: 10px;
  border: 1px solid #222831;
  border-radius: 10px;
  width: 100px;
}
